@import '../../assets/';

.status_screen {
  @include headerGradientGreenRight();

  &--fail {
    @include headerGradientRedRight();
  }

  &__content {
    position: relative;
    width: 100vw;
    height: 100vh;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__status_block {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    @extend %status-screen-title-mobile;
  }

  &__info_list {
    background-color: $white;
  }

  &__gift_aid_block {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_value {
      font-weight: 700;
      line-height: 140%;
      color: $mainBlack;
    }

    &_text {
      line-height: 130%;
      color: $mainBlack;
    }
  }

  &__thanks_block {
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    bottom: 6%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;

    &_text {
      color: $mainBlack;
      line-height: 128%;
    }
  }
}

.info_list_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid rgba($disabledGray, 0.2);
  }

  &__name {
    line-height: 128%;
    font-weight: 400;
    color: $transparentBlack;
    min-width: fit-content;
  }

  &__value {
    line-height: 128%;
    font-weight: 400;
    color: $mainBlack;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
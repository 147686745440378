$mainBlack: #21242b;
$lightDark: #3c434d;
$transparentBlack: #7a7c80;
$transparentGray: #9a9c9e;
$disabledGray: #8a8e94;
$disabledLite: #c4c4c4;
$disabledButtonBackground: rgba($mainBlack, 0.24);
$disabledButtonText: #F2F2F2;
$consentTextGray: #909195;
$lightGrayBg: #f5f7fa;
$mainYellow: #fee600;
$gradientRed: #ff4d5f;
$lightGreen:#88FF62;
$lightPurpure: #9388e7;
$lightRed: #fe655b;
$green: #29CF75;
$defaultBar: #fff;
$white: #fff;
$alertsRed10:#FFF0EF;
$alertsRed20: #FFE0DE;

:export {
  mainBlack: $mainBlack;
  mainYellow: $mainYellow;
  defaultBar: $defaultBar;
  gradientRed: $gradientRed;
  lightGreen: $lightGreen;
  lightDark: $lightDark;
  lightGrayBg: $lightGrayBg;
  alertsRed10: $alertsRed10;
  alertsRed20: $alertsRed20;
}

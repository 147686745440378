@import '../../assets/index.scss';

%small-border {
  box-shadow: 0px 0px 0px 0.5px rgba(147,136,231,1);
  -moz-box-shadow: 0px 0px 0px 0.5px rgba(147,136,231,1);
  -webkit-box-shadow: 0px 0px 0px 0.5px rgba(147,136,231,1);
}

.choose-bank-block {
  display: flex;
  cursor: pointer;

  .current-bank-image {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 7.5vh;
    height: 7.5vh;
    margin-right: 2.4vw;
    background: #fff;
    border-radius: 50%;

    img {
      width: 2.4vh;
      height: 2.4vh;
    }

    .bank-logo {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &.border {
      @extend %small-border;
    }
  }

  .spinner {
    width: 6vh;
    height: 6vh;
  }

  .cash-title {
    @extend %main-small-600-mobile;
  }
}

.bank-name-block {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #fff;
  @extend %small-border;
  border-radius: 25px;
  width: 44.8vw;
}

.choose-title {
  display: flex;
  align-items: center;
  @extend %choose-bank-btn-mobile;
}

.choose-bank-block-desktop {
  display: flex;
  justify-content: space-between;
  width: 227px;
  cursor: pointer;

  .current-bank-image {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    @include size(50px);
    background: #fff;
    border-radius: 50%;

    .bank-logo {
      max-width: 50px;
      max-height: 50px;
      border-radius: 50%;
    }

    &.border {
      @extend %small-border;
    }
  }

  .spinner {
    @include size(40px);
  }

  .cash-title {
    @extend %main-small-600;
  }

  .bank-name-block {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 168px;
    background: #fff;
    @extend %small-border;
    border-radius: 25px;
  }

  .choose-title {
    display: flex;
    align-items: center;
    @extend %choose-bank-btn;
  }

}

@import './style.theme.scss';

%regular-family {
  font-family: Mulish;
  font-style: normal;
  font-weight: 400;
}

%main-family {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
}

%bold-family-styles {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
}

%family-styles-600 {
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
}

// General styles for different components

%regular-Mulish-small {
  @extend %main-family;
  font-size: 11px;
  line-height: 14px;
  color: $transparentBlack;
}

%regular-Mulish-normal {
  @extend %main-family;
  font-size: 18px;
  line-height: 23px;
  color: $mainBlack;
}

%small-text {
  @extend %main-family;
  font-size: 12px;
  line-height: 15px;
  color: $mainBlack;
}

%small-bold {
  @extend %bold-family-styles;
  font-size: 12px;
  line-height: 15px;
  color: $mainBlack;
}

%main-text {
  @extend %main-family;
  font-size: 18px;
  line-height: 23px;
  color: $mainBlack;
}

%main-medium {
  @extend %main-family;
  font-size: 16px;
  line-height: 20px;
  color: $mainBlack;
}

%main-medium-600 {
  @extend %family-styles-600;
  font-size: 16px;
  line-height: 20px;
  color: $mainBlack;
}

%main-medium-bold {
  @extend %bold-family-styles;
  font-size: 16px;
  line-height: 20px;
  color: $mainBlack;
}

%main-text-small {
  @extend %main-family;
  font-size: 14px;
  line-height: 130%;
  color: $mainBlack;
}

%main-small-600 {
  @extend %family-styles-600;
  font-size: 14px;
  line-height: 130%;
  color: $mainBlack;
}

%main-small-bold {
  @extend %bold-family-styles;
  font-size: 14px;
  line-height: 130%;
  color: $mainBlack;
}

%main-text-bold {
  @extend %bold-family-styles;

  font-size: 18px;
  line-height: 23px;
  color: $mainBlack;
}

%main-text-600 {
  @extend %family-styles-600;

  font-size: 18px;
  line-height: 23px;
  color: $mainBlack;
}

%main-text-normal-grey {
  @extend %main-text;

  color: $lightDark;
}

%main-text-bold-grey {
  @extend %main-text-bold;

  color: $lightDark;
}

%main-text-small-grey {
  @extend %main-text-small;

  color: $disabledGray;
}

%title-in-header {
  @extend %family-styles-600;

  font-size: 22px;
  line-height: 127%;
  color: $mainBlack;
}

%title-normal {
  @extend %main-family;

  font-size: 22px;
  line-height: 28px;
  color: $mainBlack;
}

%bank-acc-name {
  @extend %bold-family-styles;

  font-size: 20px;
  line-height: 140%;
  color: $mainBlack;
}

// Styles by screens & components

%status-screen-title {
  @extend %bold-family-styles;

  font-size: 22px;
  line-height: 28px;
  text-align: right;
  color: $mainBlack;

  text-transform: uppercase;
}

%status-screen-amount {
  @extend %bold-family-styles;

  font-size: 36px;
  line-height: 140%;
  color: $mainBlack;
}

%splash-loader-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: $mainBlack;

  text-align: center;
}

%num-pad {
  @extend %bold-family-styles;
  font-size: 21px;
  line-height: 25px;
  color: $mainBlack;
}

%choose-bank-btn {
  font-family: Muli;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: $mainBlack;
}

%amount {
  @extend %bold-family-styles;
  font-size: 48px;
  line-height: 125%;
  text-align: center;
}

%amount-input-field {
  @extend %bold-family-styles;
  font-size: 40px;
  line-height: 140%;
  color: $mainBlack;
}

%error-screen-title {
  @extend %bold-family-styles;
  font-size: 22px;
  line-height: 28px;
  color: $mainBlack;
}

%status-screen-gift-aid-value {
  @extend %bold-family-styles;
  font-size: 24px;
  line-height: 140%;
  color: $mainBlack;
}

%toast-text {
  font-size: 16px;
  line-height: 22px;
  color: $white;
}

%consent-aisp-message {
  @extend %main-family;
  font-size: 14px;
  line-height: 130%;
  color: $consentTextGray;
}
html, body {
  height: 100%;
  margin: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: #F5F7FA;
}

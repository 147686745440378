@import '../../assets/';

.status_screen {
  @include desktopGradientGreen();
  position: relative;
  width: 100%;
  height: 100%;

  &--fail {
    @include desktopGradientRed();
  }
  
  &__content {
    box-sizing: border-box;
    position: absolute;
    top: 139px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 539px;
    height: 670px;
    padding: 50px 82px 71px;
    border-radius: 9px;
    background-color: $white;
    box-shadow: 0px 4px 10px rgba(60, 67, 77, 0.05), 0px 2px 30px rgba(60, 67, 77, 0.1);
  }

  &__copy_block {
    position: absolute;
    top: 20px;
    left: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &_icon {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }

    &_text {
      @extend %main-text-small;
      text-decoration: underline;
    }
  }

  &__top_icon {
    width: 64px;
    height: 64px;
    margin-bottom: 12px;
  }

  &__title {
    @extend %status-screen-title;
    text-transform: none;
    margin-bottom: 28px;
  }

  &__info_list {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 15px;
    border-radius: 9px;
    background-color: #FBFBFB;
  }

  &__gift_aid_block {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_icon {
      width: 26px;
      height: 26px;
    }

    &_value {
      @extend %main-small-bold;
      line-height: 140%;
      margin: 6px 0;
    }

    &_text {
      @extend %main-text-small;
    }
  }

  &__thanks_block {
    display: flex;
    flex-direction: column;
    width: 100%;

    &_border {
      height: 1px;
      width: 100%;
      margin: 20px 0;
      background-color: rgba($disabledGray, 0.2);
    }

    &_text {
      @extend %main-text-small;
      text-align: center;
    }
  }
}

.info_list_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;

  &:not(:last-child) {
    border-bottom: 1px solid rgba($disabledGray, 0.2);
  }

  &__name {
    @extend %main-text-small;
    color: $transparentBlack;
    margin-right: 30px;
    min-width: fit-content;
  }

  &__value {
    @extend %main-text-small;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.toast {
  bottom: 9px !important;
}

@media screen and (min-width: 1025px) and (max-height: 900px) {
  .status_screen {
    &__content {
      top: 15.444vh;
      width: 59.889vh;
      height: 74.444vh;
      padding: 5.555vh 9.111vh 7.889vh;
    }

    &__copy_block {
      top: 2.222vh;
      left: 3.333vh;
    }

    &__copy_block_icon {
      width: 2.667vh;
      height: 2.667vh;
      margin-right: 0.556vh;
    }

    &__copy_block_text {
      font-size: 1.556vh;
    }

    &__top_icon {
      width: 7.111vh;
      height: 7.111vh;
      margin-bottom: 1.333vh;
    }

    &__title {
      margin-bottom: 3.111vh;
      font-size: 2.444vh;
      line-height: 127%;
    }

    &__info_list {
      padding: 0 2.222vh;
      margin-bottom: 1.667vh;
    }

    &__gift_aid_block_icon {
      width: 2.889vh;
      height: 2.889vh;
    }

    &__gift_aid_block_value {
      margin: 0.667vh 0;
      font-size: 1.556vh;
    }

    &__gift_aid_block_text {
      font-size: 1.556vh;
    }

    &__thanks_block_text {
      font-size: 1.556vh;
    }

    &__thanks_block_border {
      margin: 2.222vh 0;
    }

    .toast {
      width: 25.333vh;
      height: 5.333vh;

      span {
        margin-right: 0.556vh;
        font-size: 1.778vh;
        line-height: 137%;
      }

      img {
        width: 2.222vh;
        height: 1.667vh;
      }
    }
  }

  .info_list_item {
    padding: 1.778vh 0;

    &__name {
      margin-right: 3.333vh;
      font-size: 1.556vh;
    }

    &__value {
      font-size: 1.556vh;
    }
  }

}
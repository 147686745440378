@import '../../assets/index.scss';

.payment-details__desktop-so {
  font-size: 1vh; 
  margin-bottom: 5px;
}

.payment-details__desktop-so-bold {
  font-size: 1vh;
  font-weight: bold;
  margin-bottom: 5px;
}

.payment-details__desktop-so-mobile {
  font-size: 1.5vh; 
  margin-bottom: 5px;
}

.payment-details__desktop-so-bold-mobile {
  font-size: 1.5vh;
  font-weight: bold;
  margin-bottom: 5px;
}

.background-left-top-gradient {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  background-color: $lightGrayBg;
}

.amount-entry, .payment-details {
  display: flex;
  height: 100%;
  align-items: center;
  flex-direction: column;
  background-color: $lightGrayBg;

  h5 {
    @extend %title-in-header;
    font-weight: bold;
    margin: 5px 0;
  }

  &__content--ref {
    margin: 0;
  }
}

.payment-details {
  &__header {
    height: 0;
  }

  &__review_button {
    text-transform: none;
    width: fit-content !important;
    padding: 0 4.8vw;
  }

  &__desktop {
    &-background {
      @include desktopGradientPurpleAndCream();
      height: 100%;

      .payment-details__footer_pay_button {
        height: 56px;
      }

      .payment-details__review_button {
        font-size: 14px !important;
        height: 38px;
        width: 57% !important;
        padding: 0;
        margin-bottom: 15px;
      }

      .payment-details__fee_notes {
        @extend %main-text-small;
        line-height: 114%;
        width: 295px;
        height: 36px;
        display: inline-block;
        margin: 15px 0 20px;
      }
    }

    &-wrapper {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: space-between;
      width: 366px;
      height: 630px;
      box-shadow: 0px 4px 10px rgba(60, 67, 77, 0.05), 0px 2px 30px rgba(60, 67, 77, 0.1);
      border-radius: 9px;
      background-color: $white;
      padding: 30px 88px 40px 86px;
      margin: 139px auto 0 auto;
    }

    &-backArrow {
      position: absolute;
      left: 24px;
      top: 30px;
    }

    &-title {
      @extend %main-text-bold;
      font-size: 22px;
      padding: 0;
      margin: 0;
      text-align: center;
    }

    &-description {
      @extend %main-text-small-grey;
      padding: 10px 0 20px 0;
      margin: 0;
      text-align: center;
      border-bottom: 1px solid rgba($disabledGray, 0.4);
    }

    &-button {
      display: flex;
      justify-content: center;

      & > button {
        width: 193px;
      }
    }
  }

  &__cancel_popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &_button {
      width: fit-content;
      min-width: 200px;
      margin-bottom: 20px;
      border-radius: 40px;
    }

    &_link {
      @extend %main-text-bold;
      text-decoration: underline;
    }
  }

  &__powered_by_bopp {
    @extend %main-text-small;
    margin-top: 4px;
  }
}


.amount-entry {
  .close-button {
    position: absolute;
    top: 3.6%;
    right: 6.4%;
  }

  &__logo {
    position: absolute;
    top: 33px;
    left: 39px;
    width: 113px;
    height: 56px;
  }

  &__info {
    position: absolute;
    top: 36px;
    right: 30px;
    width: 24px;
    height: 24px;
  }

  &__content {
    position: relative;
    text-align: center;
    padding: 13.87% 0 0;
    width: 100%;

    &-wrapper {
      padding: 0 10.135%;
    }

    &--title {
      @extend %main-small-bold-mobile;
      margin: 0 0 3.35%;
    }

    &-bank-wrap {
      margin: 0 0 6.689%;
    }

    &--amount {
      border-top: 2px solid rgba(33, 36, 43, 0.4);
    }

    &--unsettled-amount {
      @extend %main-small-bold-mobile;
      margin: 6.69% 0 3.35%;
    }
  }

  &__footer {
    position: absolute;
    left: 0;
    bottom: 4.5%;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: 51.5vw;
    }
  }

  &.amount-entry--desktop {
    @include desktopGradientPurpleAndCream();

    .amount-entry__content {
      margin-top: 139px;
      padding: 0;

      &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 366px;
        height: 600px;
        box-shadow: 0px 4px 10px rgba(60, 67, 77, 0.05), 0px 2px 30px rgba(60, 67, 77, 0.1);
        border-radius: 9px;
        background-color: $white;
        padding: 30px 88px 40px 86px;
        margin: 0 auto;
      }

      & > button {
        width: auto;
        margin-top: 39px;
        padding: 16px 77px;
      }

      &--title {
        margin: 20px 0 10px 0;
      }

      &--amount {
        padding-bottom: 64px;
        margin-bottom: 26px;
      }

      &--input-amount {
        width: 81.97%;
        margin: 0 auto;
      }

      &--unsettled-amount {
        margin: 70px 0 25px 0;
      }

      &--bank {
        font-size: 18px !important;
      }
    }
    .amount-entry__desktop-footer {
      width: 53%;
      margin: 0 auto;
    }
  }
}

.desktop {
  @include desktopGradientPurpleAndCream();
}

.payment-details {

  h5 {
    margin: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding-bottom: 9.34%;

    &--request {
      padding: 26.67% 5.334% 0;
      text-align: center;
      &_desktop {
        padding: 0;
        text-align: center;
      }
    }

    &--title {
      @extend %main-text-small-mobile;
      margin: 0 0 5.97% 0;

      &_desktop {
        @extend %main-text-small;
        margin: 20px 0;
      }
    }

    &--bank {
      padding: 0 0 4.78% 0;
      border-bottom: 1px solid rgba(33, 36, 43, 0.1);
      &_desktop {
        margin: 0;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(33, 36, 43, 0.1);
      }
    }

    &--amount {
      @extend %amount-mobile;
      position: relative;
      margin: 4.48% 0 3%;

      &_desktop {
        @extend %amount;
        position: relative;
        margin: 30px 0 10px 0;

        p {
          margin: 0;
        }
      }

      p {
        margin: 0;
      }

      img {
        margin-left: 15px;
      }
    }

    &--cash-amount {
      font-size: 80px;
      line-height: 100px;
    }

    &--edit {
      position: absolute;
      top: 15px;
      right: 20px;
    }

    &--ref {
      @extend %main-text-mobile;
      padding-bottom: 5.98%;
      margin-bottom: 5.98%;
      &_desktop {
        @extend %main-text;
        padding-bottom: 40px;
        margin-bottom: 20px;
      }
    }

    &--ref-with-border {
      border-bottom: 1px solid rgba(33, 36, 43, 0.1);
    }
  }

  &__footer {
    width: 100%;
    text-align: center;

    button {
      width: 51.5vw;
      height: 56px;
    }

    &--pay {
      width: 52%;
      margin: 0 auto;

      button {
        width: 100% !important;
      }
    }

    &--terms-text {
      width: 65%;
      font-size: 2.1vh;
      line-height: 130%;
      margin: 2.4% auto 5.07%;
      &_desktop {
        width: 65%;
        font-size: 14px;
        line-height: 130%;
        margin: 9px auto 49px;
      }
    }
  }

  &__footer--absolute {
    position: absolute;
    left: 0;
    bottom: 5.248%;
  }

  &__footer_pay_button {
    height: 8.4vh;
  }

  &__choose-bank {
    display: flex;
    justify-content: center;
  }

  .button-lock{
    width: 12px;
    height: 15px;
  }
}

.payment-details__desktop-background {
  .payment-details__footer--pay {
    min-width: 193px;
  }
}

.additional-info {
  @extend %main-text-small-grey;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  padding: 0 0 35px;
  overflow-y: auto;

  &--space-between {
    justify-content: space-between;
  }

  &__content {
    width: 100%;

    &-img {
      display: block;
      width: 83px;
      height: 83px;
      margin: 30px auto;
    }

    &-title {
      @extend %main-text-bold;
      text-align: center;
    }

    &-subtitle {
      @extend %main-text-small;
      margin: 0 20px !important;
      text-align: center;
      padding: 20px 0 40px 0;
      border-bottom: 1px solid rgba($disabledGray, 0.2);

      &_link {
        @extend %main-text-small;
      }
    }

    &-inputs-block {
      padding-top: 38px;

      &_desktop {
        padding: 55px 15px 0 15px;
        margin-top: 20px;
        border-top: 1px solid #8A8E94;
      }
    }
  }

  p {
    margin: 0 auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    max-width: 100%;
  }

  &--description {
    text-align: center;
    padding: 10px 35px 40px 35px;
  }

  &--input-block {
    padding: 0 20px 15px 20px;

    p {
      margin: 0;
    }

    &_desktop {
      @extend %main-text-small-grey;
      padding: 0;
      margin: 10px 0;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
      max-width: 100%;
    }

    &-asterisk {
      color: $lightRed;
    }

    &_email-input {
      margin-bottom: 10px !important;
    }

    &-caption {
      font-size: 14px;
      color: #909195;
    }

    &-label {
      color: #A6A7AA;
      font-weight: 600;
    }

    input, textarea {
      @extend %main-text-small;
      box-sizing: border-box;
      margin: 2px 0 5px 0;
      padding: 8px 10px;
      width: 100%;
      border: 1px solid #E9E9EA;
      border-radius: 8px;
      resize: none;
      -webkit-appearance: none;
    }

    input:focus, textarea:focus {
      outline: none;
    }

    &-error {
      border: 1px solid $lightRed;
    }

    input {
      height: 50px;
    }

    textarea {
      height: 110px;
    }

    &-checkboxes {
      margin: 15px 23px 0 23px;
    }

    &-checkbox {
      display: flex;
      justify-content: start;
      margin-top: 10px;

      &_desktop {
        display: flex;
        justify-content: start;
        margin-top: 10px;
        @extend %main-text-small;
        color: #4D5055;

        p {
          margin: 0 0 0 10px;
        }
      }

      &_custom {
        display: none;
      }

      &_custom + label {
        display: inline-flex;
        align-items: center;
      }

      &_custom + label:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid $disabledLite;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80% 100%;
      }

      &_custom:checked + label:before {
        border-color: $mainYellow;
        background-color: $mainYellow;
        background-image: url("../../assets/images/check-icon-white.svg");
      }

      p {
        font-size: 14px;
        line-height: 18px;
        color: #4D5055;
        margin: 0 0 0 10px;
      }
    }
  }

  &__footer {
    width: 52%;

    &_desktop {
      margin: 0 auto;
      width: 52%;

      & > Button:last-child {
        margin-top: 20px;
      }
    }
  }

  &__footer--gift-aid {
    margin-top: 20px;

    & > Button:last-child {
      margin-top: 20px;
    }
  }

  &__footer--absolute {
    position: absolute;
    left: 50%;
    bottom: 4.5%;
    transform: translateX(-50%);
  }

  &--payee-note {
    display: flex;
    padding-bottom: 3px;
    align-items: center;
    justify-content: start;

    img {
      padding-right: 12px;
    }

    p {
      @extend %main-text-small;
      font-weight: bold;
      margin: 0;
    }

    &-text {
      padding-left: 36px;
    }
  }
}

.payment-details-mobile {
  position: relative;
  height: 100%;
  text-align: center;
  background-color: $lightGrayBg;

  &__inner {
    height: 100%;
    padding: 0 5.33%;
    box-sizing: border-box;
  }

  &__title {
    @extend %main-small-bold-mobile;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;

    border: 1px solid #D8D8D8;
    border-radius: 16px 16px 0px 0px;
    box-shadow: 0px 2px 5px rgba(60, 67, 77, 0.05);

    background-color: $white;

    &--half-rounded {
      border-radius: 16px 16px 0px 0px;
    }
  }

  &__amount {
    @extend %amount-mobile;
    color: $mainBlack;
  }

  &__reference {
    @extend %main-text-mobile;
  }

  &__review_button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #E9E9EA;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 16px 16px;
  }

  &__review_button_icon {
    margin-right: 3%;
  }

  &__review_button_title {
    @extend %main-small-bold-mobile;
  }

  &__fee_notes {
    @extend %main-text-small-mobile;
    display: inline-block;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: 1.2%;
    transform: translateX(-50%);
    width: 69.1%;
  }

  &__terms_text {
    @extend %main-text-small-mobile;

    a {
      text-decoration: underline;
    }
  }

  &__pay_button {
    width: fit-content;
    padding: 0 18.4vw;
  }

  &__pay_button_title {
    @extend %main-text-bold-mobile;
  }

  &__pay_button_icon {
    margin-right: 1.6vw;
  }

  &__powered_by_bopp {
    @extend %main-text-small-mobile;
    margin-top: 0.6vh;
  }
}

@media screen and (min-width: 1025px) and (max-height: 900px) {
  .amount-entry.amount-entry--desktop {
    .amount-entry {
      &__content {
        margin-top: 15.44vh;
      }

      &__content-wrapper {
        width: 40.667vh;
        height: 66.667vh;
        padding: 3.33vh 9.78vh 4.44vh 9.56vh;
      }

      &__content--title  {
        margin: 2.22vh 0 1.11vh 0;
        font-size: 1.556vh;
      }

      &__content--amount {
        padding-bottom: 7.11vh;
        margin-bottom: 2.89vh;
      }

      &__content--input-amount {
        width: 33.33vh;
      }

      &__content--unsettled-amount {
        margin: 7.78vh 0 2.78vh 0;
        font-size: 1.56vh;

      }
    }

    .desktop_amount_input_field {
      &__currency {
        height: 7.778vh;
        font-size: 4.444vh;
      }

      input {
        font-size: 4.444vh;
        //max-width: 12.111vh !important;
      }
    }

    .account-name--desktop.account-name--small-font {
      font-size: 2vh !important;
    }

    .button-wrapper {
      height: 6.222vh;
      font-size: 2vh;
    } 
  }

  .payment-details {
    &__desktop-wrapper {
      width: 40.667vh;
      height: 67.667vh;
      padding: 3.333vh 9.778vh 4.444vh 9.556vh;
      margin: 15.444vh auto 0 auto;

      .additional-info__content {
        img {
          top: 3.333vh;
          left: 2.667vh;
          width: 2.667vh;
          height: 2.667vh;
        }
      }

      .additional-info__content-img {
        width: 9.22vh !important;
        height: 9.22vh !important;
        margin: 3.33vh auto;
      }

      .payment-details__desktop-title {
        font-size: 2.444vh;
        line-height: 100%;
      }

      .payment-details__desktop-description {
        padding: 1.111vh 0 2.222vh 0;
        font-size: 1.556vh;
      }

      .additional-info--input-block_desktop  {
        margin: 1.111vh 0;
        font-size: 1.556vh;

        input {
          height: 5.556vh;
          margin: 0.222vh 0 0.556vh 0;
          padding: 0.889vh 1.111vh;
          font-size: 1.556vh;
        }

        textarea {
          height: 12.222vh;
          margin: 0.222vh 0 0.556vh 0;
          padding: 0.889vh 1.111vh;
          font-size: 1.556vh;
        }
      }

      .additional-info--input-block-caption {
        font-size: 1.556vh;
      }

      .additional-info--payee-note {
        padding-bottom: 0.333vh;

        &-text {
          padding-left: 4vh;
        }

        img {
          padding-right: 1.333vh;
        }

        p {
          font-size: 1.556vh;
        }
      }

      .additional-info--input-block-checkbox {
        margin-top: 1.111vh;

        p {
          font-size: 1.556vh;
          line-height: 128%;
        }

        label::before {
          width: 2.22vh;
          height: 2.22vh;  
        }
      }

      .button-wrapper {
        width: 21.444vh;
        height: 6.222vh;
        font-size: 2vh;
        line-height: 128%;
      }

      // add gift aid yo your payment screen

      .additional-info__content-title {
        line-height: 128%;
        font-size: 2vh;
      }

      .additional-info__content-subtitle {
        margin: 0 2.222vh !important;
        padding: 2.222vh 0 4.444vh 0;
        font-size: 1.556vh;

        a {
          font-size: 1.556vh;
        }
      }

      .additional-info--input-block-checkboxes {
        margin: 1.667vh 2.556vh 0 2.556vh;
      }

      .additional-info--input-block-checkbox_desktop {
        margin-top: 1.111vh;
        font-size: 1.556vh;

        p {
          margin: 0 0 0 1.111vh;
        }

        label::before {
          width: 2.222vh;
          height: 2.222vh;
        }
      }
      .additional-info__footer_desktop {
        button:last-child {
          margin-top: 2.222vh;
        }
      }

      //add gift aid information screen

      .additional-info__content-inputs-block_desktop {
        padding: 6.111vh 1.667vh 0 1.667vh;
        margin-top: 2.222vh;
      }

      // review payment details screen

      .payment-details__content--title_desktop  {
        margin: 2.222vh 0;
        font-size: 1.556vh;
      }

      .account-name.account-name--desktop.account-name--regular-font {
        font-size: 2.444vh !important;
      }

      .payment-details__content--amount_desktop {
        margin: 3.333vh 0 1.111vh 0;
        font-size: 5.33vh;
      }

      .payment-details__content--ref_desktop {
        padding-bottom: 4.444vh;
        margin-bottom: 2.222vh;
        line-height: 128%;
        font-size: 2vh;
        border-bottom: 1px solid rgba($disabledGray, 0.4);
      }

      .payment-details__content--line {
        height: 1px;
        width: 100%;
        color: #8A8E94;
      }

      .payment-details__content--bank {
        padding: 0 0 1.9vh;
      }

      .payment-details__review_button {
        font-size: 1.556vh !important;
        margin-bottom: 1.667vh !important;
        height: 4.222vh;
      }

      .payment-details__fee_notes {
        width: 32.778vh;
        height: 4vh;
        margin: 1.667vh 0 2.222vh;
        font-size: 1.556vh;
      }

      .choose-bank-block-desktop {
        width: 25.222vh;

        .spinner {
          width: 4.44vh;
          height: 4.44vh;
        }

        .current-bank-image {
          width: 5.556vh;
          height: 5.556vh;

          .bank-logo {
            height: 5.556vh;
            width: 5.556vh;
          }
        }

        .bank-name-block {
          width: 18.667vh;

          .choose-title {
            font-size: 2vh;
            line-height: 123%;
          }
        }
      }

      .payment-details__footer {
        &--terms-text_desktop {
          font-size: 1.556vh;
          margin: 1vh auto 5.444vh;
        }

        &--pay {
          min-width: 21.44vh;

          img {
            width: 1.778vh;
            height: 1.778vh;
            margin-right: 0.667vh;
          }
        }
      }
    }
  }
}
@import './typography.scss';

%main-small-bold-mobile {
  @extend %main-small-bold;
  font-size: 2.1vh;
}

%regular-Mulish-normal-mobile {
  @extend %regular-Mulish-normal;
  font-size: 2.7vh !important;
  line-height: 128% !important;
}

%amount-mobile {
  @extend %amount;
  font-size: 7.2vh;
}

%num-pad-mobile {
  @extend %num-pad;
  font-size: 3.1vh;
  line-height: 119%;
}

%regular-Mulish-small-mobile {
  @extend %regular-Mulish-small;
  font-size: 1.6vh;
  line-height: 154%;
}

%main-text-small-mobile {
  @extend %main-text-small;
  font-size: 2.1vh;
}

%main-text-mobile {
  @extend %main-text;
  font-size: 2.7vh;
  line-height: 128%;
}

%main-small-600-mobile {
  @extend %main-small-600;
  font-size: 2.1vh;
}

%choose-bank-btn-mobile {
  @extend %choose-bank-btn;
  font-size: 2.7vh;
  line-height: 133%;
}

%title-in-header-mobile {
  @extend %title-in-header;
  font-size: 3.3vh;
  line-height: 127%;
}

%status-screen-gift-aid-value-mobile {
  @extend %status-screen-gift-aid-value;
  font-size: 3.6vh;
}

%main-text-bold-mobile {
  line-height: 128%;
}

%main-text-600-mobile {
  font-size: 2.7vh;
  line-height: 127%;
  font-weight: 600;
}

%splash-loader-title-mobile {
  font-size: 2.7vh;
  line-height: 127%;
  font-weight: 700;
}

%toast-text-mobile {
  font-size: 2.4vh;
  line-height: 137%;
  color: $white;
}

%status-screen-title-mobile {
  line-height: 127%;
  font-weight: 700;
  color: $mainBlack;
}
@mixin backgroundBlock($_color, $_bottom, $_left: $_bottom) {
  box-shadow: -$_left $_bottom 0px 0px $_color;
  -moz-box-shadow: -$_left $_bottom 0px 0px $_color;
  -webkit-box-shadow: -$_left $_bottom 0px 0px $_color;
}

@mixin itemBorders($_topHeight: 50%, $__bottomHeight: 27%, $_topWidth: 8.06%, $_bottomWidth: 81.5%) {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;

    display: block;
    width: $_topWidth;
    height: $_topHeight;

    border-top: 1px solid #707070;
    border-left: 1px solid #707070;
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    right: 3px;
    bottom: 3.5px;

    display: block;
    width: $_bottomWidth;
    height: $__bottomHeight;

    border-right: 1px solid #707070;
    border-bottom: 1px solid #707070;
    pointer-events: none;
  }
}

@mixin size($_width, $_height: $_width) {
  width: $_width;
  height: $_height;
}

@mixin headerGradientYellowRight() {
  @include headerGradient(15deg, $mainYellow);
}

@mixin headerGradientGreenRight() {
  @include headerGradient(15deg, $lightGreen);
}

@mixin headerGradientRedRight() {
  @include headerGradient(15deg, $gradientRed);
}

@mixin headerGradientYellowLeft() {
  @include headerGradient(-15deg, $mainYellow);
}

@mixin headerGradient($angel, $color) {
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 21.74vh;
    background-color: transparent;
    background: linear-gradient($angel, transparent 44%, $color 100%);  
  }
}

@mixin colorfulGradientYellow() {
  background-color: $lightGrayBg;
  
  &::before {
    content: '';
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    
    background:
      linear-gradient(57deg, #9dfdf8 8%, #cef8a2 25%, #f7ed40 60%, transparent 75%),
      linear-gradient(145deg, #fddd01 30%, transparent 40%),
      linear-gradient(-45deg, #fddd01 10%, transparent 50%),
      linear-gradient(241deg, #ffff 10%, transparent 40%);
    background-blend-mode: color;
  }
}

@mixin desktopGradientGreen() {
  @include desktopGradient(#e5f8e5, #e5f8e5);
}

@mixin desktopGradientRed() {
  @include desktopGradient(#f6e3e5, #f6e3e5);
}

@mixin desktopGradientPurpleAndCream {
  @include desktopGradient(#e6dff1, #f5f2e1);
  background-color: #f8f9fb;
}

@mixin desktopGradient($color1, $color2) {
  background: 
    linear-gradient(20deg, $color1 10%, transparent 50%),
    linear-gradient(-120deg, $color2 10%, transparent 50%);
}